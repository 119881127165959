.admin-finance {
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
    height: auto;
    padding-bottom: 10px;
}
.admin-finance-table {
    grid-column: 1/7;
    grid-row: 1/8;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    border: 1px solid #0085CE;
}
.admin-finance-table-title {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.admin-finance-table-data {
    width: 98%;
    height: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}