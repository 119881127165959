.admin-stat{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
    height: auto;
    padding-bottom: 10px;
}
.admin-stat-table{
    grid-column: 1/5;
    grid-row: 1/9;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    border: 1px solid #0085CE;
}
.ad-stat-table{
    height: auto;
}
#stat-tr-th{
    display: flex;
    align-items: center;
    justify-content: center;
}
#organization{
    width: auto;
    height: auto;
}

#organization::placeholder{
    color: white;
}
.stat-tr-th,.stat-tr-td{
    height: 45px;
}
.close-icon{
    width: auto;
    height: auto;
}
.stat-tr-th input{
    background-color: #6fbbe4;
    border-style: none;
    border-radius: 10px;
    outline: none;
    color: #FFF;
    font-weight: 600;
    text-align: center;
    font-size: 17px;
}

.admin-edit-div{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #3b3b3b;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.admin-edit-header{
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: flex-end;
}
.admin-edit-header-btn{
    height: 100%;
    width: 5%;
    display: flex;
}

.admin-edit-image{
    max-height: 95%;
    height: 100%;
    width: 100%;
    max-width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
#editimage{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 95%;
    height: 95%;
    box-sizing: border-box;
}
#image{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.user-edit-image{
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#prizm{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    display: flex;

    justify-content: center;
}
.edit-header-btn a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.ad-stat-thead{
    height: 45px;
    width: 100%;
}
.ad-stat-tbody td{
    height: 35px;
    width: auto
}
.ad-stat-tbody tr:hover{
    background-color: #0085CE;
    cursor: pointer;
}
.ad-stat-tbody tr:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.ad-stat-tbody tr:active{
    transform: scale(0.98);
    transition: 0.1s;
}

.stat-tr-td{
    width: auto;
    max-height: 30px;
    height: 30px;
    text-align: center;
    margin-top: 5px;
    border: 2px solid #FFF;
}
.stat-table-data{
    overflow: auto;
}
.ad-stat-select input, #ad-select, option {
    width: 93%;
    height: 30px;
    padding: 0px 10px;
    border-style: none;
    text-align: center;
    border-radius: 10px;
    background-color: #9CDCFF;
    outline: none
}

.admin-stat-table-title{
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.admin-stat-table-title-text{
    width: auto;
    height: 60%;
    padding: 0px 15px;
    border-radius: 10px;
    color: #FFF;
    display: flex;
    font-size: 1.3rem;
    background-color: #0085CE;
    justify-content: center;
    align-items: center;
}
.admin-stat-table-data{
    width: 98%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-stat-table-data input{
    width: auto;
}
.admin-stat-table-date{
    height: 8%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.admin-stat-table-date input{
    width: 40%;
    height: 30px;
    padding: 0px 10px;
    border-style: none;
    border-radius: 10px;
    background-color: #9CDCFF;
    outline: none;
}
.admin-stat-table-date-input{
    width: 40%;
    height: 50%;
}
.admin-stat-table-date-send{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-stat-table-date-send button{
    width: auto;
    padding: 0px 20px;
    height: 75.5%;
    background-color: #0085CE;
    color: #FFF;
    font-size: 1rem;
    border-radius: 10px;
    border-style: none;
}


.admin-stat-table-date-check{
    width: 90%;
    height: 10%;
    display: flex;
    color: #FFF;
    font-size: 1.2rem;
    justify-content: space-evenly;
    align-items: center;
}
.admin-stat-table-date-check input{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
}
.ad-stat-select{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-stat-diagram{
    grid-column: 5/13;
    grid-row: 1/9;
    background-color:#4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0085CE;
}
.admin-stat-diagram-big{
    z-index: 2;
    grid-column: 1/13;
    grid-row: 1/13;
    background-color:#4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0085CE;
}
.admin-stat-diagram-big .admin-stat-diagram-data{
    width: 95%;
    height: 90%;
}
.admin-stat-diagram-big .admin-stat-diagram-btn {
    display: none;
}

.admin-stat-diagram-title{
    width: 100%;
    height: 12%;
    display: flex;
}
.admin-stat-diagram-title-container-1{
    width: 25%;
    height: 100%;
    background-color: #4DAFE4;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
.admin-stat-diagram-title-container-2{
    width: 53%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-stat-diagram-title-container-2 .div-loading{
    width:40%
}
#researchtime:active{
    transform: scale(1);
}
#researchtime{
    cursor: text;
    width: auto;
    max-width: 200px;
}
#ad-research-datetime{
    width: auto;
    max-width: 150px;
}
.admin-stat-diagram-title-text{
    width:auto;
    height: 60%;
    padding:0px 10px;
    background-color: #0085CE;
    border-radius: 10px;
    margin-left: 20px;
    font-size: 1.2rem;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-stat-diagram-title-info{
    width: auto;
    padding: 0px 10px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 1.2rem;
    border-bottom: 2px solid #FFF;
}
.big-diagramm{
    height: 100%;
    width: 22%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    box-sizing: border-box;
}
.big-diagramm-icon{
    width: auto;
    background-color: #0085CE;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: auto;
}
.big-diagramm-icon:hover{
    transform: scale(1.06);
    transition: 0.2s;
}
.big-diagramm-icon:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.big-diagramm-icon:active{
    transform: scale(0.97);
    transition: 0.2s;
}
.admin-stat-diagram-data{
    width: 80%;
    height: 70%;
}
.admin-stat-diagram-data canvas{
    background-color:'white';
    border-radius: 10px;
    padding:10px
}
.admin-stat-diagram-btn{
    height: 18%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.admin-stat-diagram-btn button{
    width: auto;
    height: 40px;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    border-style: none;
    padding: 0px 10px;
    color: #FFF;
    font-size: 1.2rem;
}

.admin-research-table{
    background-color:#4DAFE4;
    grid-column: 1/13;
    grid-row: 9/13;
    z-index: 1;
    border-radius: 10px;
    height: 98%;
    overflow: auto;
    border: 1px solid #0085CE;
    box-sizing: border-box;
    position: relative;
}
.admin-research-table-active{
    background-color:#4DAFE4;
    grid-column: 1/13;
    grid-row: 5/13;
    z-index: 1;
    border-radius: 10px;
    height: 98%;
    border: 1px solid #0085CE;
    box-sizing: border-box;
    position: relative;
}
.pagination-block{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.css-wjh20t-MuiPagination-ul{
    width: auto;
}
.css-1oj2twp-MuiPagination-root{
    width: auto;
    display: flex;
    align-items: center;
}
.css-0{
    width: auto;
    align-items: center;
    display: flex;
}
.css-wjh20t-MuiPagination-ul>li{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-research-table-close{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin-research-table-close button{
    padding: 2px 10px;
    width: auto;
    height: 70%;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    border-style: none;
    color: #FFF;
    margin-right: 15px;
    font-size: 1rem;
}
.admin-research-table-close-active{
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.admin-research-table-close-active button{
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    height: 70%;
    width: auto;
    margin-right: 15px;
    border-style: none;
    color: #FFF;
    font-size: 1rem;
    padding: 2px 10px;
}
.css-nhb8h9 li{
    height: auto;
    width: auto;
}
.MuiPagination-root .MuiPagination-text{
    width: auto;
}
.admin-research-table-data{
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    width:auto;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    width: auto;
}
.css-yf8vq0-MuiSelect-nativeInput{
    width: auto;
}
.select-count{
    width: auto;
    display: flex;
    align-items: center;
    color: #FFF;
}
.pag-div{
    width: auto;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    border-radius: 10px;
    background-color: #0085CE;
    color: #FFF;
    cursor: pointer;
}
.pag-div:hover{
    background-color:rgba(0, 0, 0, 0.04);
    transform: scale(1.001);
    transition: 0.3s;
}
.pag-div:not(hover){
    background-color:#0085CE;
    transform: scale(1);
    transition: 0.1s;
}
.pag-div:active{
    transform: scale(0.995);
    transition: 0.1s;
}

.select-count>select{
    width: auto;
    height: auto;
    padding: 5px 5px;
    margin: 0px 20px 0px 5px;
    outline: none;
    border-radius: 10px;
    background-color: #0085CE;
    color: #FFF;
    font-size: 1rem;
}
.admin-research-table-data-active{
    width: 100%;
    height: 90%;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
}
.admin-research-table-close a {
    width: auto;
    height: auto;
}
.admin-research-table-data-active .admin-research-table-date{
    max-height: 90%;
    height: auto;
}
.admin-research-table-close-active a {
    width: auto;
    height: auto;
}
.ad-research-tbody{
    height: auto;
}
.ad-research-tr{
    width: 100%;
    height: auto;
    min-height: 40px;
}
.ad-research-th{
    height: 40px;
    width: auto;
    color: #FFF;
    box-sizing: border-box;
    background-color: #0085CE;
    cursor: pointer;
    border-radius: 10px;
}
.ad-research-th-text{
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.empt{
    width: auto;
    display: none;
    align-items: center;
    justify-content: center;
}
.ad-research-th-text:hover .empt{
    display: flex;
    width: auto;
}
.ad-research-th:active{
    transform: scale(0.98);
    transition: 0.1s;
}
#ad-research-search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
}
.serach-field input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #FFF;
    height: 70%;
    font-size: 1rem;
}
#ad-search-name{
    background-color: #0085CE;
}
.search-title{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.serach-field{
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.serach-field{
    width: auto;
    border: 1px solid white;
    border-radius: 10px;
    height: 80%;
}
#demo-helper-text-aligned{
    width: auto;
}
.ad-research-td{
    height: 35px;
    width: auto;
    color: #FFF;
    text-align: center;
    font-size: 1rem;
    border: 2px solid #FFF;
}
.table-sop{
    max-width: 300px;
    overflow: hidden;
}
.stat-tr-td-div{
    width: 100%;
    height: 100%;
    display: flex;
}
.stat-status{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stat-tr-td-info{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ad-research-thead{
    height:8% ;
}
.ad-research-tbody{
    width: 100%;
    height: 80%;
    font-size: 1rem;
}

.ad-research-tbody tr:hover{
    background-color: #0085CE;
    cursor: pointer;
}
.ad-research-tbody tr:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.ad-research-tbody tr:active{
    transform: scale(0.98);
    transition: 0.1s;
}
.detalization{
    z-index: 100;
    grid-column: 1/13;
    grid-row: 9/13;
    background-color:#4DAFE4;
    border: 1px solid #0085CE;
    border-radius: 10px;
}
.detalization .detalization-header{
    height: 15%;
}
.detalization .detalization-body{
    height: 85%;
}
.detalization .detalization-body-diagram,.detalization .pathologies-diagram{
    grid-row: 1/13;
}
.detalization-active{
    z-index: 1;
    grid-column: 1/13;
    grid-row: 5/13;
    background-color:#4DAFE4;
    border: 1px solid #0085CE;
    border-radius: 10px;
}
.detalization .detalization-body-text,.detalization .pathologies-text{
    display: none;
}
.datalization-header-active{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datalization-header-active button{
    width: auto;
    height: auto;
    padding: 2px 10px;
    width: auto;
    height: 70%;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    border-style: none;
    color: #FFF;
    margin-right: 15px;
    font-size: 1rem;
}
.detalization-header{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detalization-header a{
    width: auto;
    display: flex;
    justify-content: flex-end;
}
.detalization-header-info{
    width: auto;
    height: auto;
    padding: 5px 10px;
    margin-left: 15px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0085CE;
    border-radius: 10px;
}
.detalization-body{
    height: 90%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
}
.detalization-body-text{
    grid-column: 1/6;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.detalization-text{
    width: 94%;
    height: 30%;
    border-bottom: 2px solid #FFF;
    margin-left: 20px;
    color: #FFF;
    font-size: 1.2rem;
}
.detalization-body-diagram{
    grid-column: 1/6;
    grid-row: 3/13;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
.pathologies-text{
    grid-column: 6/13;
    grid-row: 2/3;
    font-size: 1.2rem;
    width:100%;
    height: 83%;
    color: #FFF;
    display: flex;
    padding: 0px 15px;
    box-sizing: border-box;
    align-items: center;

}
.pathologies-text-title{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
    background-color: #0085CE;
}
.pathologies-diagram{
    grid-column: 6/13;
    grid-row: 3/13;
    padding: 15px;
    box-sizing: border-box;
}










.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #0085CE;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 26px;
width: 26px;
left: 4px;
bottom: 4px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #0085CE;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

@media screen and (max-width:1100px){
    .admin-page-content{
        height: auto;
    }
    .admin-stat{
        width: 98%;
    } 
    .admin-stat-diagram-data{
        width: 90%;
    }
}
@media screen and (max-width:800px){
    .admin-stat{
        height: 200%;
    }
    .admin-stat-table{
        grid-column: 3/11;
        grid-row: 1/6;
    }
    .admin-stat-diagram{
        grid-column: 1/13;
        grid-row: 6/10;
    }
    .admin-stat-diagram{
        height: 97%;
    }
    .admin-stat-diagram-btn button{
        width: 44%;
        height: 50px;
    }
}
@media screen and (max-width:500px){
    .admin-stat-table{
        grid-column: 1/13;
        grid-row: 1/6;
    }
    .pagination-block{
        display: none;
    }
    .admin-edit-header-btn{
        width: 15%;
    }
    .admin-stat-diagram{
        grid-column: 1/13;
        grid-row: 6/11;
        height: 90%;
    }
    .admin-research-table{
        grid-row: 10/13
    }
    .admin-stat-diagram-title-info{
        font-size: 1.2rem;
    }
    .admin-stat-diagram-title{
        height: 20%;
    }
    .admin-stat-diagram-title-text{
        margin: 0;
        height: 80%;
    }
    .admin-stat-diagram-title{
        flex-direction: column;
        height: 20%;
    }
    .admin-stat-diagram-title-container-1{
        width: 100%;
        height: 50%;
        justify-content: center;
    }
    .admin-stat-diagram-title-container-2{
        width: 100%;
        height: 50%;
        justify-content: center;
        font-size: 1.5rem;
    }
    .admin-stat-diagram-data{
        width: 98%;
        height:80%;
        display: flex;
        justify-content: center;
        align-items:stretch;
    }
    #ad-select{
        height: 30px;
    }
    .admin-stat-table-date input{
        height: 30px;
        width: 38%;
    }
}
@media screen and (max-width:400px){
    .admin-research-table{
        grid-row: 9/13;
        flex-direction: column;
        align-items: center;
    }
    .admin-research-table-active{
        grid-row:6/13;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-stat-diagram-data{
        height: 50%;
    }
    .admin-stat-diagram-btn{
        flex-direction: column;
        height: 30%;
    }
    .admin-stat-diagram-btn button{
        width: 70%;
    }
    .admin-research-table-close{
        display: flex;
        justify-content: space-between;
        width: 95%;
    }
    .admin-research-table-close button{
        margin-right:0;
        box-sizing: border-box;
        height: 85%;
        width: auto;
    }
    .admin-research-table-close-active button{
        margin: 0;
    }
    .admin-research-table-close-active{
        width: 95%;
    }
}